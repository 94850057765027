import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../common/app-config/app-config.service';
import { GlobalIndianDescriptionMappingApiModel, HsnClsMainMasterApiModel, HsnClsMasterApiModel, ToClassifierApiModel } from '../../models/hsn-cls/hsn-cls';

@Injectable({
  providedIn: 'root'
})
export class HsnClsService {

  apiGstrServiceUrl = AppConfigService.ApiGstrServiceUrl;
  constructor(private _httpClient:HttpClient) { }

  UploadFile(formdata:any): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/HsnClassification/HsnClassificationUpload',formdata);    
  }
  GetMasterData(currentPage : number, pageSize :number ): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/GetHsnClassificationMaster?currentPage=' + currentPage + '&pageSize=' + pageSize);
  }
  Insert_Upate_HSNClassificationMaster(_hsnClsMaster:HsnClsMasterApiModel): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/HsnClassification/InsertUpateHSNClasMaster',_hsnClsMaster);    
  }
  DownloadHsnClsMasterFile(): Observable<any> {
    
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/DownloadHSNClasMaster', {
      responseType: 'blob'
    });
  }

  HomeScrennFileInfoData(currentPage : number, pageSize :number ): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/GetHomeScreeFileInfoGrid?currentPage=' + currentPage + '&pageSize=' + pageSize);
  }
  GetToClassifyData(currentPage : number, pageSize :number,identity ): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/GetToClassifyFileInfoGrid?currentPage=' + currentPage + '&pageSize=' + pageSize+ '&identity=' + identity);
  }
  GetUploadData(currentPage : number, pageSize :number ): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/GetUploadScreenFileInfoGrid?currentPage=' + currentPage + '&pageSize=' + pageSize);
  }
  GetHsnClasRequestDetailsData(id:number,notifier:any,toLogin:any,isRequestFromCompleteScreen:Number): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/GetHsnRequestDetails?id=' + id+ '&notifier=' + notifier+ '&toLogin=' + toLogin+ '&isRequestFromCompleteScreen=' + isRequestFromCompleteScreen);
  }
  GetMainMasterData(currentPage : number, pageSize :number ,typeSelected): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/GetHsnClassificationMainMaster?currentPage=' + currentPage + '&pageSize=' + pageSize+'&fileType='+typeSelected);
  }
  GetIndianHSNCodeDetails(indianHSNCode : string,idToChnage :number,section :number,masterId:string): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/GetIndianHSNCodeDetails?indianHSNCode=' + encodeURIComponent(indianHSNCode)+'&idToChnage='+idToChnage+'&masterId='+masterId+'&section='+section);
  }
  DownloadHsnClsMainMasterFile(typeSelected): Observable<any> {
    
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/DownloadHSNClasMainMaster?fileType=' + typeSelected);
  }
  UpdateToClassifyData(toClassifierUpdateData:any, toLogin:string): Observable<any> {
    
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/HsnClassification/UpdateToClassifyData?toLogin=' + toLogin,toClassifierUpdateData);    
  }
  Insert_Upate_HSNClassificationMainMaster(_hsnClsMainMaster:HsnClsMainMasterApiModel): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/HsnClassification/InsertUpateHSNClasMainMaster',_hsnClsMainMaster);    
  }
  DownloadHsnFileOrTemplate(isTemplate,fileId,type): Observable<any> {
    
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/DownloadHsnFileOrTemplate?isTemplate=' + isTemplate+'&fileId='+fileId+'&type='+type, {
      responseType: 'blob'
    });
  }
  DownloadCompleteFile(fileId): Observable<any> {
    
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/DownloadHsnClsCompleteFile?id=' + fileId, {
      responseType: 'blob'
    });
  }
  getHsnClsEnabled(): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/GetHsnClsEnableStatus');
  }
  public getHsnClsUserRoles() {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/HsnClassification/GetHsnClsRoles'
    )
}
DownloadHsnSummaryFile(noOfdays,action): Observable<any> {
  
  return this._httpClient.get(
    this.apiGstrServiceUrl + '/HsnClassification/DownloadHsnSummaryFile?noOfdays=' + noOfdays+'&action='+action, {
    responseType: 'blob'
  });
}
GetMainMasterPartNumberSearchData(partNumber): Observable<any> {
  return this._httpClient.get(
    this.apiGstrServiceUrl + '/HsnClassification/GetMainMasterPartNumberSearchData?partNumber=' + partNumber);
}
GetMasterDataWithHsnCode(hsncode:string){
  return this._httpClient.get(
    this.apiGstrServiceUrl + '/HsnClassification/GetTaxMasterByIndianHSNCode?indianHSNCode=' + hsncode);
}
GetAvaliableHsnHeadingDescription(id:number): Observable<any> {
  return this._httpClient.get(
    this.apiGstrServiceUrl + '/HsnClassification/GetAvaliableHsnHeadingDescription?hsnClsDocumentUploadId=' + id);
}
UpdateHSNClassificationMapping(_mapping:GlobalIndianDescriptionMappingApiModel[]): Observable<any> {
  return this._httpClient.post(
    this.apiGstrServiceUrl + '/HsnClassification/UpdateHSNClassificationMapping',_mapping);    
}
GetReportData(tableType:string): Observable<any> {
  return this._httpClient.get(
    this.apiGstrServiceUrl + '/HsnClassification/GetReportData?tableType=' + tableType);
}
  
DocumentsDownload(fileid): Observable<any> {
  debugger;
  return this._httpClient.get(this.apiGstrServiceUrl + '/HsnClassification/DocumentsDownload?fileid=' + fileid+"&tableType= abc'", {
      responseType: 'blob'
  });
}
getTypeOfClient(): Observable<any> {
  return this._httpClient.get(
    this.apiGstrServiceUrl + '/HsnClassification/GetTypeOfClient');
}
  
}


