import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../common/app-config/app-config.service';
import { GlobalApiService } from '../../global/global-api/global-api.service';
import { SaveAndPrepareData, getSaveRequestData ,GetReturnStatusData, GetReturnViewStatusData, downloadErrorReport, getJsonSaveRequestData, downloadPDFReport, getGSTR1SavedExcelRequestData} from 'src/app/shared/models/post/pst-md-file-returns/post-file-return-data';
import { GSTR1ComparisionSummaryRequest } from 'src/app/shared/models/get/get-gstr1-summary/get-gstr1-summary';
import { GSTR1SummaryDownloadViewModel } from '../returns-model.service';

@Injectable({
  providedIn: 'root'
})
export class FileReturnsService {

  constructor(private _httpClient: HttpClient) { }
  ewayBillAPIBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
  
  getGstInAndReportPeriodBasedOnRetunType(returnType: string): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/FilterData' + '/' + returnType
    );
  }

  saveAndPrepare(saveAndPrepareData : SaveAndPrepareData ): Observable<any>{
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/SaveGSTReturn', saveAndPrepareData
    )
  }

  getGSTRSaveJsonDownloadFlag(): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTRSaveJsonDownlaodFlag'
    )
  }

  getSaveRequestedDetails(SaveReturnsRequestDomain : getSaveRequestData ): Observable<any>{
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetReturnRequestDetails', SaveReturnsRequestDomain 
    )
    
  }

  getGSTR1SaveExcelRequestedDetails(SaveReturnsRequestDomain : getGSTR1SavedExcelRequestData ): Observable<any>{
    SaveReturnsRequestDomain.returnType='GSTR1SaveExcel'
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetReturnRequestDetails', SaveReturnsRequestDomain 
    )
    
  }

  getSaveJsonRequestedDetails(SaveReturnsRequestDomain : getJsonSaveRequestData ): Observable<any>{
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetReturnRequestDetails', SaveReturnsRequestDomain 
    )
    
  }
  getGstr1Status(getReturnStatusData : GetReturnStatusData ): Observable<any>{
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTR1Status', getReturnStatusData 
    )
    
  }
  getGstr1ViewStatus(getReturnViewStatusData : GetReturnViewStatusData ): Observable<any>{
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GSTR1ViewStatus', getReturnViewStatusData 
    )
    
  }
  DownloadSaveErrorReport( downloadErrorReportData: downloadErrorReport): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/DownlaodSaveErrorReport', downloadErrorReportData, { responseType: 'blob' }
    );
  }
  getGstr1ComparisonSummary( gSTR1ComparisionSummaryRequest: GSTR1ComparisionSummaryRequest): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTR1ComparisonSummary', gSTR1ComparisionSummaryRequest 
    )
  }

  summarydownload(GSTR1SummaryDownload: GSTR1SummaryDownloadViewModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GSTR1SummaryPdfDownload', GSTR1SummaryDownload
    );
  }

  
  generatePDF(GSTR1SummaryDownload: downloadPDFReport): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/ReturnSummaryPdfDownload', GSTR1SummaryDownload
    );
  }

  doGSTR1SaveHistoryReportRequest(saveAndPrepareData : SaveAndPrepareData ): Observable<any>{
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GSTReturnSaveExcelDownload', saveAndPrepareData
    )
  }

}
